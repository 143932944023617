import $ from './jquery';
import slick from 'slick-carousel';

$(() => {
  let index = 1;
  // выставляю ширину dots и arrows для каждой карусели
  $('.slides-container').on('init', function() {
    $(this).append(`
      <button class="next-slide">
        <span class="index">1</span>/${
          $(this).find('.slide').length
        } · <span class="caption">Дальше</span>
      </button>
    `);
  });

  $('.slides-container').on('setPosition init', function() {
    const imageWidth = $(this)
      .find('.image-container')
      .innerWidth();
    const imageHeight = $(this)
      .find('.image-container')
      .innerHeight();
    const carouselWidth = $(this)
      .find('.wrapper:first-of-type')
      .innerWidth();
    let carouselPadding = 60;

    if (window.matchMedia('(max-width: 1440px)').matches) {
      carouselPadding = 35;
    } else {
      carouselPadding = 60;
    }

    if (window.matchMedia('(max-width: 768px)').matches) {
      $(this)
        .find('.next-slide')
        .css('left', '0');
      $(this)
        .find('.slick-prev')
        .css('top', imageHeight / 2);
      $(this)
        .find('.slick-next')
        .css({
          left: 'unset',
          top: imageHeight / 2,
          width: (imageWidth / 4) * 3,
          height: imageHeight,
        });
      $(this)
        .find('.slick-dots')
        .css({
          width: carouselWidth - 20,
          top: imageHeight + 15,
        });
    } else {
      $(this)
        .find('.next-slide')
        .css('left', imageWidth + carouselPadding);
      $(this)
        .find('.slick-next')
        .css({
          left: imageWidth + carouselPadding,
          width: (imageWidth / 4) * 3,
          height: imageHeight,
        });
      $(this)
        .find('.slick-dots')
        .css('width', carouselWidth);
    }
  });

  $('.slides-container').on('beforeChange', function() {
    const carouselLength = $(this).find('.slide').length;
    const slideIndex = $(this).slick('slickCurrentSlide') + 2;

    if (slideIndex === carouselLength + 1) {
      $(this)
        .find('.next-slide span.index')
        .html(1);
    } else {
      $(this)
        .find('.next-slide span.index')
        .html(slideIndex);
    }

    if (slideIndex === carouselLength) {
      $(this)
        .find('.next-slide span.caption')
        .html('Сначала');
    } else {
      $(this)
        .find('.next-slide span.caption')
        .html('Дальше');
    }
  });

  $('.slides-container').slick({
    prevArrow: `
      <button class="slick-prev slick-arrow" type="button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 16">
          <polyline points="8 1 1 8 8 15"/>
        </svg>
      </button>
    `,
    nextArrow: `
      <button class="slick-next slick-arrow" type="button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 16">
          <polyline points="8 1 1 8 8 15"/>
        </svg>
      </button>
    `,
    arrows: true,
    dots: true,
    infinite: true,
    speed: 300,
    fade: true,
    cssEase: 'linear',
  });

  $('button.next-slide').on('click', function() {
    $(this)
      .closest('.slides-container')
      .slick('slickNext');
  });

  $('.slick-dots').on('click', e => {
    if ($(e.target).is('li')) {
      const $carouselContainer = $(e.target).parents('.slides-container');
      const carouselLength = $carouselContainer.find('.slide').length;
      const slideDotIndex = $(e.target).text();

      if (slideDotIndex === carouselLength + 1) {
        $carouselContainer.find('.next-slide span.index').html(1);
      } else {
        $carouselContainer.find('.next-slide span.index').html(slideDotIndex);
      }

      if (slideDotIndex === carouselLength) {
        $carouselContainer.find('.next-slide span.caption').html('Сначала');
      } else {
        $carouselContainer.find('.next-slide span.caption').html('Дальше');
      }
    }
  });
});
