import 'waypoints/lib/noframework.waypoints.min'; // import waypoint from 'waypoints';
import 'waypoints/lib/jquery.waypoints.min';

import $ from './jquery';
import router from './router';
/* eslint-disable */

// каждый waypoint, класс, — это кусочек того, что будет выполняться с выбранными элементами.

let article = Array.from(document.getElementsByTagName('article')); // нужно перебирать элементы, которые попадают в зону видимости
let divider = Array.from(document.getElementsByClassName('divider'));

const $navArticle = $('.article span');
const $navSection = $('.section-divide span');
$('.link:last-of-type').hide();
$('.link:nth-of-type(2)').addClass('last');

const $mainCover = $('.main-cover');
if ($mainCover.length !== 0) {
  let mainCover = new Waypoint({
    element: $('.main-cover'),
    handler: function() {
      router.navigateWithoutAnimation('/', 10);
    },
    offset: '-20%',
  });
}

if (article.length !== 0) {
  article.map(function(i) {
    new Waypoint({
      element: i,
      handler: function(direction) {
        if (direction === 'down') {
          const $article = $(i);
          const $section = $article.closest('section');

          $navSection.html($section.find('h2').html());
          $navSection.attr('data-url', $section.attr('id'));

          // article is shown in nav
          if ($article.attr('id')) {
            const href = $section.attr('id') + '/' + $article.attr('id');

            $navArticle.html(
              $article
                .closest('article')
                .find('h2')
                .html()
            );
            $navArticle.attr('href', href);

            router.navigateWithoutAnimation(href, 10);
            $('.link:last-of-type').show();
            $('.link:nth-of-type(2)').removeClass('last');
          }
        }
      },
      offset: '25%',
      // continuous: false // при скролле на длинное расстояние срабатывает только последнее пересечение
    });
  });

  article.map(function(i) {
    new Waypoint({
      element: i,
      handler: function(direction) {
        if (direction === 'up') {
          const $article = $(i);
          const $section = $article.closest('section');

          $navSection.html($section.find('h2').html());
          $navSection.attr('data-url', $section.attr('id')); // запись в data-url меню

          // article is shown in nav
          if ($article.attr('id')) {
            const href = $section.attr('id') + '/' + $article.attr('id');

            $navArticle.html(
              $article
                .closest('article')
                .find('h2')
                .html()
            );
            $navArticle.attr('href', href);

            router.navigateWithoutAnimation(href, 10);
            $('.link:last-of-type').show();
            $('.link:nth-of-type(2)').removeClass('last');
          } else {
            // article is hidden in nav
            const $previosBlock = $article.closest('article').prev();
            const previosBlockTagName = $previosBlock
              .prop('tagName')
              .toLowerCase();
            if (previosBlockTagName == 'div') {
              const href = $section.attr('id');
              router.navigateWithoutAnimation(href, 100);

              $('.link:last-of-type').hide();
              $('.link:nth-of-type(2)').addClass('last');
            } else {
              const href = $section.attr('id') + '/' + $previosBlock.attr('id');
              $navArticle.html($previosBlock.find('h2').html());
              $navArticle.attr('href', href);

              router.navigateWithoutAnimation(href, 10);
              $('.link:last-of-type').show();
              $('.link:nth-of-type(2)').removeClass('last');
            }
          }
        }
      },
      offset: function() {
        return -this.element.clientHeight + 200; // срабатывает, когда нижняя граница блока касается верхней стороны за 200пх до
      },
      // continuous: false
    });
  });
}

if (divider.length !== 0) {
  // возможно, нужно убирать/менять урл не при пересечении section, а при пересечении блока .divider
  divider.map(function(i) {
    new Waypoint({
      element: i,
      handler: function(direction) {
        if (direction === 'down') {
          const $section = $(i).closest('section');
          $navSection.html($section.find('h2').html());

          const href = $section.attr('id');
          router.navigateWithoutAnimation(href, 100);

          $('.link:last-of-type').hide();
          $('.link:nth-of-type(2)').addClass('last');
        }
      },
      // continuous: false,
      offset: '25%',
    });
  });

  divider.map(function(i) {
    new Waypoint({
      element: i,
      handler: function(direction) {
        if (direction === 'up') {
          const $section = $(i).closest('section');
          $navSection.html($section.find('h2').html());

          const href = $section.attr('id');
          router.navigateWithoutAnimation(href, 100);

          // скрываю меню
          $('.link:last-of-type').hide();
          $('.link:nth-of-type(2)').addClass('last');
        }
      },
      offset: function() {
        return -this.element.clientHeight + 300;
      },
      // continuous: false
    });
  });
}
