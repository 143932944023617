import Navigo from 'navigo';
import $ from './jquery';

const router = new Navigo('', true, '#!');

const scrollTo = (selector, { duration = 300, offset = -130 } = {}) => () => {
  if ($(selector).offset()) {
    const to = $(selector).offset().top + offset;

    router.isScrolling = true;

    $('html, body').animate({ scrollTop: to }, duration, () => {
      router.isScrolling = false;
    });
  }
};

const $article = $('article');
if ($article.length !== 0) {
  router.navigateWithoutAnimation = (href, time) => {
    if (!router.isScrolling) {
      router.pause(true);
      router.navigate(href);

      // Reset last resolved route so that subsequent clicks
      // on the same link work after scroll.
      router._lastRouteResolved = null; // eslint-disable-line

      setTimeout(() => router.resume(), time);
    }
  };

  router.historyAPIUpdateMethod('pushState');

  router
    .on({
      ':section/:article': params =>
        scrollTo(`#${params.section} #${params.article}`)(),
      ':section': params => scrollTo(`#${params.section}`)(),

      brand: scrollTo('#brand'),
      'brand/manifesto': scrollTo('#manifesto'),
      'brand/mission': scrollTo('#mission'),
      'brand/platform': scrollTo('#platform'),
      'brand/values': scrollTo('#values'),
      'brand/audience': scrollTo('#audience'),
      'brand/text-style-guide': scrollTo('#text-style-guide'),
      // 'brand/principles': scrollTo('#principles'),

      customers: scrollTo('#customers'),
      'customers/segments': scrollTo('#segments'),
      'customers/jtbd': scrollTo('#jtbd'),

      policy: scrollTo('#policy'),
      'policy/tone-of-voice': scrollTo('#tone-of-voice'),
      'policy/content-principles': scrollTo('#content-principles'),
      'policy/partnerships': scrollTo('#partnerships'),
      'policy/editorial-policy': scrollTo('#editorial-policy'),

      design: scrollTo('#design'),
      'design/logo': scrollTo('#logo'),
      'design/typeface': scrollTo('#typeface'),
      'design/colors': scrollTo('#colors'),
      'design/advertising': scrollTo('#advertising'),
      'design/illustrations': scrollTo('#illustrations'),
      'design/font': scrollTo('#font'),

      // architechture: scrollTo('#architechture'),
      // 'architechture/facades': scrollTo('#facades'),
      // 'architechture/interiors': scrollTo('#interiors'),

      taste: scrollTo('#taste'),
      quality: scrollTo('#quality'),
      openness: scrollTo('#openness'),
      trust: scrollTo('#trust'),
      community: scrollTo('#community'),
      cityconcept: scrollTo('#city-concept'),
      familyconcept: scrollTo('#family-concept'),
      principlesheader: scrollTo('#principles-header'),
      momentsheader: scrollTo('#moments-header'),

      '': scrollTo('.page-wrapper'),
    })
    .resolve();
}
export default router;
