import $ from 'jquery';

$(window).on('load resize', () => {
  $('.table-cell.1').addClass('extrabold');
  $('.table-letter').show();

  // показываю и прячу огромную букву на фоне, когда таблица в экране
  const charTable = [].slice.call(
    document.querySelectorAll('.multilanguage-char-table')
  );

  let listeners = [];
  const charTableObserver = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          $('.table-letter').show();

          // внутри обсервера, чтобы до этого не считалась
          if (window.matchMedia('(width < 768px)').matches) {
            const $tableCell = $('.table-cell.1');
            $tableCell.removeClass('extrabold');
            $tableCell.addClass('highlight');

            const listener = () => {
              const charTableHeight = document.querySelector(
                '.multilanguage-char-table'
              ).offsetHeight;

              const charTableCount = $('.multilanguage-char-table .table-cell')
                .length;
              const charTableOffset =
                -document
                  .querySelector('.multilanguage-char-table')
                  .getBoundingClientRect().top +
                window.innerHeight / 2;

              if (charTableOffset >= 0) {
                const calculatedCellNumber = (
                  charTableOffset /
                  (charTableHeight / charTableCount)
                ).toFixed(0);
                const calculatedCell = $(`.table-cell.${calculatedCellNumber}`);
                $('.table-letter').html(calculatedCell.text());
                $tableCell.removeClass('highlight');
                setTimeout(() => {
                  calculatedCell.removeClass('highlight');
                }, 300);
                calculatedCell.addClass('highlight');
              }
            };
            window.addEventListener('scroll', listener);
            listeners.push(listener);
          }
        } else {
          listeners.forEach(l => window.removeEventListener('scroll', l));
          listeners = [];
          $('.table-letter').hide();
        }
      });
    },
    {
      rootMargin: `0px 0px 0px 0px`,
    }
  );

  charTable.forEach(entry => {
    charTableObserver.observe(entry);
  });

  if (window.matchMedia('(width > 769px)').matches) {
    const $tableCell = $('.table-cell');
    $tableCell.on('mouseover', e => {
      const letter = $(e.currentTarget).text();
      $('.table-letter').html(letter);
      $('.table-cell.1').removeClass('extrabold');
      $(e.currentTarget).addClass('extrabold');
    });

    $tableCell.on('mouseout', e => {
      setTimeout(() => {
        $(e.currentTarget).removeClass('extrabold');
      }, 100);
    });
  }
});
