import $ from './jquery';

let root = document.querySelector('.enjoy-typeface');

// брать ширину блока при загрузке страницы. Если координаты мыши меньше половины — увеличивать первый набор значений, если больше — второй набор.
$(() => {
  if (root !== null) {
    const rootWidth = root.clientWidth;
    const rootHeight = root.clientHeight;
    const elementsToLeft = document.querySelectorAll('span.left');
    const elementsToRight = document.querySelectorAll('span.right');

    root.addEventListener('mousemove', e => {
      if (e.clientX <= rootWidth / 2 + 74) {
        elementsToLeft.forEach(function(el) {
          el.style.setProperty(
            '--text-wdth',
            rootWidth / 2 + 74 - e.clientX + 200
          );
        });
      } else {
        elementsToRight.forEach(function(el) {
          el.style.setProperty(
            '--text-wdth',
            e.clientX - (rootWidth / 2 + 74) + 200
          );
        });
      }

      $('.text-block')
        .get(0)
        .style.setProperty('--text-wght', e.clientY);
    });

    root.addEventListener('touchmove', e => {
      e.preventDefault();

      if (e.touches[0].clientX <= rootWidth / 2) {
        elementsToLeft.forEach(function(el) {
          el.style.setProperty(
            '--text-wdth',
            rootWidth / 2 - e.touches[0].clientX + 500
          );
        });
      } else {
        elementsToRight.forEach(function(el) {
          el.style.setProperty(
            '--text-wdth',
            e.touches[0].clientX - rootWidth / 2 + 500
          );
        });
      }

      $('.text-block')
        .get(0)
        .style.setProperty('--text-wght', e.touches[0].clientY);
    });
  }
});

const $fontFeatures = $('.alt-features-preview');

$('#alt-features').change(function() {
  if (this.checked) {
    $fontFeatures.get(0).style.setProperty('--ss00-switch', 'on');
    $fontFeatures.get(0).style.setProperty('--tnum-switch', 'on');
    $fontFeatures
      .find('span')
      .get(0)
      .style.setProperty('--sups-switch', 'on');
  } else {
    $fontFeatures.get(0).style.setProperty('--ss00-switch', 'off');
    $fontFeatures.get(0).style.setProperty('--tnum-switch', 'off');
    $fontFeatures
      .find('span')
      .get(0)
      .style.setProperty('--sups-switch', 'off');
  }
});

//или сделать ховеры как в обложке, а дефолтом оставлять русский
$('.language-tabs .tab').on('click', function() {
  const tabName = $(this).attr('name');

  $('.language-tabs .tab').removeClass('active');
  $(this).addClass('active');

  $('.multilanguage-slides p').removeClass('active');
  $(`.multilanguage-slides p[name=${tabName}]`).addClass('active');
});
