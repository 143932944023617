/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
(function h() {
  const heap = document.getElementById('heap');
  const heap_wrapper = document.getElementById('heap_wrapper');
  const images = heap && heap.querySelectorAll('img');
  let mouseX = 0;
  let mouseY = 0;
  let targetX = 0;
  let targetY = 0;
  let { scrollY } = window;
  let lastScrollY = 0;
  let stopAnimation = true;

  if (!heap || !images.length) return;

  fixImageOverflow(heap, images);

  const speed = 0.1;

  function animate() {
    if (stopAnimation) return;

    const top = heap.offsetTop;
    const left = heap.offsetLeft;
    const height = heap.offsetHeight;
    const width = heap.offsetWidth;
    const windowWidth = document.documentElement.clientWidth;
    const distX = mouseX - targetX;
    // Scroll correction
    const _mouseY = mouseY + scrollY - lastScrollY;
    const distY = _mouseY - targetY;

    targetX += distX * speed;
    targetY += distY * speed;

    const X = windowWidth > 767 ? ((targetX - left) / width) * 100 : 50;
    const Y = ((targetY - top) / height) * 100;

    heap.style.perspectiveOrigin = `${X}% ${Y}%`;
    requestAnimationFrame(animate);
  }

  function onMouseMove(e) {
    mouseX = e.pageX;
    mouseY = e.pageY;
    lastScrollY = scrollY;

    hover(e);
  }

  function onScroll() {
    ({ scrollY } = window);
  }

  function cb(entries) {
    if (!entries[0].isIntersecting) {
      stopAnimation = true;
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('scroll', onScroll);
      return;
    }

    stopAnimation = false;
    animate();

    if (document.documentElement.clientWidth > 768) {
      document.addEventListener('mousemove', onMouseMove);
    }

    document.addEventListener('scroll', onScroll);
    window.addEventListener('resize', () => {
      fixImageOverflow(heap, images);
    });
  }

  const observer = new IntersectionObserver(cb, { threshold: 0 });
  observer.observe(heap);

  let curr_target = null;

  function hover(e) {
    const target = e.toElement;
    if (target.tagName === 'IMG' && curr_target !== target) {
      if (curr_target) {
        curr_target.style.removeProperty('scale');
        curr_target.classList.remove('hover');
      }

      // Size compensation. Base height is 180px;
      const scale = 180 / target.offsetHeight;
      const maxScale = 2.2;
      target.style.scale = scale < maxScale ? scale : maxScale;
      target.classList.add('hover');
      curr_target = target;
    }
  }

  function fixImageOverflow(wrap, imgArray) {
    const windowWidth = document.documentElement.clientWidth;
    const wrapLeft = wrap.offsetLeft;
    const wrapWidth = wrap.offsetWidth;
    const shift = windowWidth > 767 ? 30 : 0;

    // Fix for safari image low resolution bug
    // img.style = '';
    // const widthInGrid = img.offsetWidth;
    // const heightInGrid = img.offsetHeight;
    // const origWidth = img.naturalWidth;
    // const origHeight = img.naturalHeight;
    // const scale = widthInGrid / origWidth;
    // img.style.position = 'absolute';
    // img.style.width = 'auto';
    // img.style.scale = scale;
    // img.style.top = `${0 - origHeight / 2 + heightInGrid / 2}px`;
    // img.style.left = `${0 - origWidth / 2 + widthInGrid / 2}px`;

    imgArray.forEach(img => {
      img.style.removeProperty('left'); // reset
      const { left, right } = img.getBoundingClientRect();
      if (left < 100) {
        img.style.transformOrigin = 'left center';
      }
      if (right - 100 > wrapLeft + wrapWidth) {
        img.style.transformOrigin = 'right center';
      }
      if (left < shift) img.style.left = `${shift}px`;
      if (right - shift > wrapLeft + wrapWidth) {
        img.style.left = `-${shift}px`;
      }
    });

    // Reset overflow after fix all overflow images
    heap_wrapper.style.overflow = 'visible';
  }
})();
