import $ from './jquery';

const $target = $('button.arrow-button');
// const $activeLink = $('.link.active');
// const $parentLink = $target.closest('.link').first();
let isOpen = false;

/* eslint-disable */

$target.on('click', function(e) {
  if (isOpen === false) {
    if ($(this).hasClass('section-divide')) {
      console.log($(this).closest('.link'));
      $(this)
        .closest('.link')
        .find('.nav-popup')
        .fadeIn(150);
      // $('.section-divide + .nav-popup').fadeIn(150);
    } else {
      let section = $('.section-divide span').attr('data-url');
      $('.nav-popup' + '.' + section).fadeIn(150);
    }

    $(this)
      .closest('.link')
      .addClass('active');
    $('svg', this).addClass('rotated');
    isOpen = true;
  } else {
    if ($('.link.active').hasClass('active')) {
      $('.nav-popup').fadeOut(150);
      $('.link.active').removeClass('active');
      $('svg').removeClass('rotated');
    }
    isOpen = false;
  }

  e.stopPropagation();
});

// скрывает по оутсайд-клику
$(document).on('click', function(e) {
  if (e.target.className !== 'nav-popup') {
    $('.nav-popup').fadeOut(150);
    $('.link.active').removeClass('active');
    $('svg').removeClass('rotated');
    isOpen = false;
  }
});

// скрывает при переходе по пункту меню
$('.nav-popup a').on('click', function(e) {
  $('.nav-popup').fadeOut(150);
  $('.link.active').removeClass('active');
  $('svg').removeClass('rotated');
  isOpen = false;
  e.stopPropagation();
});
