function f(entries) {
  if (!entries[0].isIntersecting) {
    // entries[0].target.firstChild.style.opacity = 0;
    entries[0].target.firstChild.style.display = 'none';
  } else {
    // entries[0].target.firstChild.style.removeProperty('opacity');
    entries[0].target.firstChild.style.removeProperty('display');
  }
}

const iframemap = document.getElementById('iframemap');

if (iframemap) {
  const observer = new IntersectionObserver(f, { threshold: 0 });
  observer.observe(iframemap);
}
