// FYI this script is based on this Google's article:
// https://developers.google.com/web/fundamentals/performance/lazy-loading-guidance/images-and-video/

/* eslint-disable */

import Plyr from 'plyr';

document.addEventListener('DOMContentLoaded', function() {
  let lazyElements = [].slice.call(document.querySelectorAll('.lazy'));
  let active = false;

  const handleLazyElement = element => {
    element.classList.remove('lazy');

    const plyrOptions = {
      controls: [
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'captions',
        'settings',
        'pip',
        'airplay',
        'fullscreen',
      ],
    };

    switch (element.tagName) {
      // случаи, в которых производятся действия с элементом
      case 'AUDIO':
      case 'DIV':
        new Plyr(element, plyrOptions);
        break;

      default:
        element.src = element.dataset.src;
    }
  };

  if ('IntersectionObserver' in window) {
    let lazyObserver = new IntersectionObserver(
      function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            handleLazyElement(entry.target);
            lazyObserver.unobserve(entry.target);
          }
        });
      },
      {
        rootMargin: '50% 0px 50% 0px',
      }
    );

    // массивы, которые проверяет обсервер
    lazyElements.forEach(function(lazyImage) {
      lazyObserver.observe(lazyImage);
    });
  } else {
    let lazyLoad = function() {
      if (active === false) {
        active = true;

        setTimeout(function() {
          lazyElements.forEach(function(lazyImage) {
            if (
              lazyImage.getBoundingClientRect().top <=
                window.innerHeight * 1.5 &&
              lazyImage.getBoundingClientRect().bottom >=
                -(window.innerHeight / 1.5) &&
              getComputedStyle(lazyImage).display !== 'none'
            ) {
              handleLazyElement(lazyImage);

              lazyElements = lazyElements.filter(function(image) {
                return image !== lazyImage;
              });

              if (lazyElements.length === 0) {
                document.removeEventListener('scroll', lazyLoad);
                window.removeEventListener('resize', lazyLoad);
                window.removeEventListener('orientationchange', lazyLoad);
              }
            }
          });

          active = false;
        }, 200);
      }
    };

    document.addEventListener('scroll', lazyLoad);
    window.addEventListener('resize', lazyLoad);
    window.addEventListener('orientationchange', lazyLoad);
  }
});
