import $ from './jquery';

$(() => {
  $('.download-plate').hover(
    function(e) {
      $(this).addClass('hover');
    },
    function(e) {
      $(this).removeClass('hover');
      $(this)
        .find('.download-menu')
        .removeClass('visible');
    }
  );

  $('.download-plate').on('click', function(e) {
    $(this)
      .find('.download-menu')
      .addClass('visible');
  });

  $('.click-copy').hover(
    function(e) {
      $(this)
        .parent('.color-sample-area-text')
        .find('.caption-hint')
        .addClass('visible');
      $(this)
        .parent('.color-sample-area-text')
        .find('.caption-done')
        .removeClass('visible');
    },
    function(e) {
      $(this)
        .parent('.color-sample-area-text')
        .find('.caption-hint')
        .removeClass('visible');
    }
  );

  $('.click-copy').on('click', function(e) {
    e.preventDefault();
    copyToClipboard($(this).text());

    $(this)
      .parent('.color-sample-area-text')
      .find('.caption-hint')
      .removeClass('visible');
    $(this)
      .parent('.color-sample-area-text')
      .find('.caption-done')
      .addClass('visible');
  });

  const setLeftValues = () => {
    $('.bunch').each(function(e) {
      let imagesCount = $(this).find('.image').length;

      $(this)
        .find('.image')
        .each(function(e) {
          let index = $(this).index();
          $(this).css('left', `${(100 / (imagesCount + 1)) * index}%`);
        });
    });
  };

  setLeftValues();

  $('.bunch .image').hover(
    function(e) {
      let imagesCount = $(this)
        .parent('.bunch')
        .find('.image').length;
      let hoveredIndex = $(this).index();

      $(this)
        .parent('.bunch')
        .find('.image')
        .each(function(e) {
          let index = $(this).index();

          if (hoveredIndex >= index) {
            $(this).css('left', `${(100 / (imagesCount + 1)) * index}%`);
          } else {
            $(this).css('left', `${(100 / imagesCount) * index}%`);
          }
        });
    },
    function(e) {
      setLeftValues();
    }
  );
});

const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};
