import $ from './jquery';

let linkID = '#fasade0';

function choosePhotoCard(item) {
  const $closestWrapper = $(item).closest('.type-gallery');

  // распространять на ближайшую группу
  $closestWrapper.children().removeClass('animation-down-up');

  // разные схемы смены z-index в зависимости от того, где фотка — слева или справа
  if ($(item).index() > 2) {
    $closestWrapper.children().each(function(index, element) {
      $(element).css('z-index', 1 + index);
    });
  } else {
    $closestWrapper.children().each(function(index, element) {
      if (index == 0) {
        $(element).css('z-index', 1);
      } else {
        $(element).css('z-index', $('.facade-type').length - index);
      }
    });
  }

  $(item).css('z-index', '10');
  $(item).addClass('animation-down-up');
}

$('.facade-type').on('click', function() {
  const $closestWrapper = $(this).closest('.type-gallery');

  // распространять на ближайшую группу
  $closestWrapper.children().removeClass('animation-down-up');

  // разные схемы смены z-index в зависимости от того, где фотка — слева или справа
  if ($(this).index() > 2) {
    $closestWrapper.children().each(function(index, element) {
      $(element).css('z-index', 1 + index);
    });
  } else {
    $closestWrapper.children().each(function(index, element) {
      if (index == 0) {
        $(element).css('z-index', 1);
      } else {
        $(element).css('z-index', $('.facade-type').length - index);
      }
    });
  }

  $(this).css('z-index', '10');
  $(this).addClass('animation-down-up');

  var newLinkID = $(this).attr('id');

  if ($('.switching-link').hasClass(newLinkID)) {
    $('.switching-link').removeClass('active');
    $('.switching-link' + '.' + newLinkID).addClass('active');
  }

  if ($('.switching-link-2').hasClass(newLinkID)) {
    $('.switching-link-2').removeClass('active');
    $('.switching-link-2' + '.' + newLinkID).addClass('active');
  }
});

// код ссылки выбиралки-переключалки

$('.switching-link').click(function(e) {
  e.preventDefault();

  var newLinkID = $(e.target).attr('href');
  if (newLinkID != linkID) {
    choosePhotoCard(newLinkID);
    $('.switching-link').removeClass('active');
    $(e.target).addClass('active');

    linkID = newLinkID;
  }
});

$('.switching-link-2').click(function(e) {
  e.preventDefault();

  var newLinkID = $(e.target).attr('href');
  if (newLinkID != linkID) {
    choosePhotoCard(newLinkID);
    $('.switching-link-2').removeClass('active');
    $(e.target).addClass('active');

    linkID = newLinkID;
  }
});
